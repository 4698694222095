function validateInput(input) {
	switch (input.type) {
		case 'checkbox':
			return validateCheckbox(input);
		case 'text':
			return validateInputField(input);
		case 'email':
		  let valid = validateEmail(input);

		  if (input.hasAttribute('data-crosscheck')) {
				let	input2 = document.querySelector(`[name=${input.getAttribute('data-crosscheck')}]`);
				valid = valid && crossCheckMail(input, input2);
			}

		  return valid;
		case 'tel':
			return validatePhonenumber(input);
		case 'select-one':
			return validateInputField(input);
	}
}

function validateInputField(input) {
	if (input.hasAttribute('required')) {
		if (input.value !== '') {
			removeError(input);
			return true;
		} else {
			addError(input);
			return false;
		}
	}

	removeError(input);
	return true;
}

function validateCheckbox(input) {
	if (input.hasAttribute('required')) {
		if(input.checked) {
			removeCheckboxError(input);
			return true;
		} else {
			addCheckboxError(input);
			return false;
		}
	}
	return true;
}

function addCheckboxError(input) {
	var fieldLabel = input.parentNode.parentNode.querySelector('.type-error');
	input.classList.add('--error');
	fieldLabel.classList.add('--show-error');
}

function removeCheckboxError(input) {
	var fieldLabel = input.parentNode.parentNode.querySelector('.type-error');
	input.classList.remove('--error');
	fieldLabel.classList.remove('--show-error');
}

function removeError(input) {
	var fieldLabel = input.parentNode.querySelector('.type-error');
	input.classList.remove('--error');
	fieldLabel.classList.remove('--show-error');
}

function addError(input) {
	var fieldLabel = input.parentNode.querySelector('.type-error');
	input.classList.add('--error');
	fieldLabel.classList.add('--show-error');
}

function validateEmail(input) {
  let email = input.value;

	if (!input.hasAttribute('required') && input.value === '') {
		removeError(input);
		return true;
  }

  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(re.test(email)) {
    removeError(input);
    return true;
  } else {
    addError(input);
    return false;
  }
}

function validatePhonenumber(input) {
	let number = input.value
	var re = new RegExp(/^\+[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
	if(re.test(number)) {
		removeError(input);
		return true;
	} else {
		addError(input);
		return false;
	}
}

function crossCheckMail(input, input2) {
	if(input2.value !== '') {
		if(input.value === input2.value) {
			removeError(input);
			return true;
		} else {
			addError(input);
			return false;
		}
	}
}
